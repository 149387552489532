import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  ScrollView,
  Modal,
  TouchableOpacity,
} from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";

const ManualVisitRedeem = ({ route, navigation }) => {
  // Extract CollabID from route.params
  const { CollabID } = route.params;

  const [collabDetails, setCollabDetails] = useState(null);
  const [venueCode, setVenueCode] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [dates, setDates] = useState([]);


const [modalVisible, setModalVisible] = useState(false);
const [modalMessage, setModalMessage] = useState("");
const [isSuccess, setIsSuccess] = useState(false);


  useEffect(() => {
    const fetchCollabDetails = async () => {
      try {
        const response = await fetch(
          `https://collabbay.com/APIControllers/GetManualRedeemDetails.php?CollabID=${CollabID}` // Use the dynamic CollabID
        );
        const data = await response.json();
        if (data.success) {
          setCollabDetails(data.details);
        } else {
          Alert.alert("Error", data.message);
        }
      } catch (error) {
        console.error("Error fetching collab details:", error);
      }
    };

    fetchCollabDetails(); // Fetch details using the dynamic CollabID
    generateDates(); // Generate date options
  }, [CollabID]); // Dependency added to re-run if CollabID changes

  const generateDates = () => {
    const today = new Date();
    const generatedDates = [];
  
    // Generate dates from today going backwards for 30 days
    for (let i = 0; i < 30; i++) {
      const date = new Date();
      date.setDate(today.getDate() - i); // Subtract days from today
      generatedDates.push(date.toDateString());
    }
  
    setDates(generatedDates); // No need to reverse, already in desired order
  };
  

  const handleBackPress = () => {
    navigation.goBack();
  };

  const handleSubmit = async () => {
    // Validate missing fields
    if (!venueCode || !selectedDate || !selectedTime) {
      let missingFields = [];
      if (!venueCode) missingFields.push("Venue Code");
      if (!selectedDate) missingFields.push("Date");
      if (!selectedTime) missingFields.push("Time");
  
      const message = `Please fill in the following field(s): ${missingFields.join(", ")}`;
      setModalMessage(message);
      setIsSuccess(false);
      setModalVisible(true);
      return;
    }
  
    if (!collabDetails || !collabDetails.CollabID) {
      setModalMessage("Collab details are not loaded yet. Please wait.");
      setIsSuccess(false);
      setModalVisible(true);
      return;
    }
  
    try {
      const response = await fetch(
        "https://collabbay.com/APIControllers/ManualInsertRedeem.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            CollabID: collabDetails.CollabID,
            VenueCode: venueCode,
            DateTime: `${selectedDate} ${selectedTime}`,
          }),
        }
      );
  
      const data = await response.json();
      if (data.success) {
        setModalMessage("Visit successfully logged. Ensure your primary content is uploaded on time.");
        setIsSuccess(true); // Set success to true
        setModalVisible(true); // Show modal
        setVenueCode("");
        setSelectedDate("");
        setSelectedTime("");
      } else {
        setModalMessage("Invalid venue code. Ask venue staff for their 2-digit code.");
        setIsSuccess(false);
        setModalVisible(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setModalMessage("An error occurred. Please try again later.");
      setIsSuccess(false);
      setModalVisible(true);
    }
  };
  
  

const renderDatePicker = () => (
  <ScrollView
    horizontal
    style={styles.datePickerContainer}
  >
    {dates.map((date, index) => (
      <TouchableOpacity
        key={index}
        style={[
          styles.dateOption,
          { backgroundColor: selectedDate === date ? "#FDF05D" : "white" },
        ]}
        onPress={() => setSelectedDate(date)}
      >
        <Text style={styles.dateOptionText}>{date}</Text>
      </TouchableOpacity>
    ))}
  </ScrollView>
);



  const renderTimePicker = () => (
    <ScrollView style={styles.timeScrollView}>
      {Array.from({ length: 40 }, (_, i) => {
        const hour = Math.floor((i + 14) / 2) % 24;
        const minute = i % 2 === 0 ? "00" : "30";
        const ampm = hour >= 12 ? "PM" : "AM";
        const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
        const timeLabel = `${adjustedHour}:${minute} ${ampm}`;

        return (
          <TouchableOpacity
            key={i}
            style={[
              styles.timeSlot,
              selectedTime === timeLabel && styles.selectedTimeSlot,
            ]}
            onPress={() => setSelectedTime(timeLabel)}
          >
            <Text>{timeLabel}</Text>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
        <Ionicons name="arrow-back" size={24} color="black" />
      </TouchableOpacity>

      {collabDetails ? (
        <View style={styles.detailsContainer}>
          <Text style={styles.header}>
            👋 Hi {collabDetails.Promoter}, confirm your visit at{" "}
            {collabDetails.BrandName}
          </Text>

          <View style={styles.inputGroup}>
            <Text>
              <Text style={styles.label}>Offer</Text>{" "}
              {collabDetails.NonCreditOffer}
            </Text>
          </View>
        </View>
      ) : (
        <Text style={styles.loadingText}>Loading collab details...</Text>
      )}

      <View style={styles.inputGroup}>
        <Text style={styles.label}>Ask the venue staff for their 2-digit collabbay venue code.</Text>
        <TextInput
          style={styles.inputVenueCode}
          value={venueCode}
          onChangeText={setVenueCode}
          keyboardType="numeric"
          maxLength={2}
          placeholder="_ _"
        />
      </View>

      <Modal
  visible={modalVisible}
  transparent
  animationType="fade"
  onRequestClose={() => setModalVisible(false)}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <Ionicons
        name={isSuccess ? "checkmark-circle" : "close-circle"}
        size={48}
        color={isSuccess ? "green" : "red"}
      />
      <Text style={styles.modalText}>{modalMessage}</Text>
      <TouchableOpacity
  style={styles.closeButton}
  onPress={() => {
    setModalVisible(false);
    if (isSuccess) {
      // Reload the page only on success
      window.location.reload();
    }
  }}
>
  <Text style={styles.closeButtonText}>Close</Text>
</TouchableOpacity>

    </View>
  </View>
</Modal>



      <View style={styles.inputGroup}>
        <Text style={styles.label}>When did you visit?</Text>
      </View>

      {renderDatePicker()}
      {renderTimePicker()}

      <Button
        style={styles.confButton}
        title="Confirm"
        onPress={handleSubmit}
        color="black"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 380,
    margin: "auto",
    padding: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
  },
  backButton: {
    position: "absolute",
    top: 20,
    left: 20,
    zIndex: 1,
    marginBottom: 30,
  },
  header: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 20,
    color: "black",
    fontWeight: "bold",
  },
  detailsContainer: {
    marginBottom: 20,
    padding: 10,
    marginTop: 30,
  },
  loadingText: {
    textAlign: "center",
  },
  inputGroup: {
    marginBottom: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontWeight: "bold",
    marginBottom: 7,
    textAlign: "center",
  },
  inputVenueCode: {
    padding: 15,
    fontSize: 28,
    textAlign: "center",
    backgroundColor: "#F2F2F2",
    borderRadius: 4,
  },
  datePickerContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  dateOption: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginRight: 8,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#CCC",
  },
  dateOptionText: {
    fontSize: 16,
  },
  timeScrollView: {
    height: 120,
    marginBottom: 20,
  },
  timeSlot: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: "#EEE",
  },
  selectedTimeSlot: {
    backgroundColor: "#FDF05D",
  },
  confButton: {
    borderRadius: 20,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 8,
    alignItems: "center",
    width: "80%",
  },
  modalText: {
    fontSize: 16,
    marginVertical: 15,
    textAlign: "center",
  },
  closeButton: {
    backgroundColor: "black",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
  },
  closeButtonText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
  },
  
  
});

export default ManualVisitRedeem;
